<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedGruppen || !selectedGruppen.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable  ref="dt" :value="gruppen" v-model:selection="selectedGruppen" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Produktgruppen" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Produktgruppen</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="GRUPPE" header="Bezeichnung" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Bezeichnung</span>
							{{slotProps.data.GRUPPE}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editGruppen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteGruppen(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog  v-model:visible="gruppenDialog" :style="{width: '450px'}" header="Produktgruppe Details" :modal="true" class="p-fluid">
					<div class="field">
						<label for="GRUPPE">Bezeichnung</label>
						<InputText id="gruppe" v-model.trim="gruppenObj.GRUPPE" required="true" autofocus :class="{'p-invalid': submitted && !gruppenObj.GRUPPE}" />
						<small class="p-invalid" v-if="submitted && !gruppenObj.GRUPPE">Bezeichnung muss angegeben werden.</small>
					</div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveGruppen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteGruppeDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="gruppenObj"><b>{{gruppenObj.GRUPPE}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteGruppeDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteGruppen" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteGruppenDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="gruppenObj">Ausgewählte Produktgruppen wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteGruppenDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedGruppen" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
	data() {
		return {
      gruppen: null,
      gruppenDialog: false,
      deleteGruppeDialog: false,
      deleteGruppenDialog: false,
      neu: false,
      gruppenObj: {},
      selectedGruppen: null,
			filters: {},
			submitted: false,
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.gruppen = await this.dataService.getProduktGruppen();
	},
	methods: {
		openNew() {
      this.gruppenObj = {};
      this.submitted = false;
      this.gruppenDialog = true;
      this.neu = true;
		},
		hideDialog() {
			this.gruppenDialog = false;
			this.submitted = false;
      this.neu = false;
		},
    async saveGruppen(){
      this.submitted = true;

      if(this.neu){
          await this.dataService.saveProduktGruppen(this.gruppenObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produktgruppe erstellt', life: 3000});
          setTimeout(async()=> {this.gruppen = await this.dataService.getProduktGruppen()}, 100);
          this.gruppenDialog = false;
          this.gruppenObj = {};
      } else {
          await this.dataService.updateProduktGruppen(this.gruppenObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produktgruppe aktualisiert', life: 3000});
          setTimeout(async()=> {this.gruppen = await this.dataService.getProduktGruppen()}, 100);
          this.gruppenDialog = false;
          this.gruppenObj = {};
      }
      this.neu = false;

    },
    editGruppen(gruppen) {
      this.gruppenObj = {...gruppen};
      this.gruppenDialog = true;
      this.neu = false;
    },
    confirmDeleteGruppen(gruppen) {
      this.gruppenObj = gruppen;
      this.deleteGruppeDialog = true;
    },
    async deleteGruppen() {
      let data = [];
      data.push(this.gruppenObj);
      await this.dataService.deleteProduktGruppen(data);

      this.deleteGruppeDialog = false;
      this.gruppenObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produktgruppe gelöscht', life: 3000});
      setTimeout(async()=> {this.gruppen = await this.dataService.getProduktGruppen()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteGruppenDialog = true;
		},
    async deleteSelectedGruppen() {
      this.deleteGruppenDialog = false;
      await this.dataService.deleteProduktGruppen(this.selectedGruppen);
      this.selectedGruppen = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Produktgruppe gelöscht', life: 3000});
      setTimeout(async()=> {this.gruppen = await this.dataService.getProduktGruppen()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
